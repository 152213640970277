// custom imports
import DefaultNav from "../nav"
import { nodeTypes } from "./nodes"
import { getMoodboard } from "./api"
import { MoodboardToolbar } from "./toolbar"
import { LoadingBar } from "../components/loading"
import { copyToClipboard, update } from "../utils"

import { selector as mbSelector } from "./state"
import { useMoodboardStore } from "./state/store"

import { useUserStore } from "../user/state/store"
import { selector as userSelector } from "../user/state"

// static data
import navData from '../assets/data/nav.json'

// third party
import { useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { useShallow } from "zustand/shallow"
import { Background, BackgroundVariant, Controls, DefaultEdgeOptions, MiniMap, ProOptions, ReactFlow, ReactFlowProvider } from "@xyflow/react"

// css sheets
import "../assets/css/mb.css"

type MoodboardProps = JSX.IntrinsicElements["div"] & {
}

const proOptions: ProOptions = { hideAttribution: true } 
const edgeOptions: DefaultEdgeOptions = {}

export default function Moodboard({...props}: MoodboardProps) {
    const {
        id,
        title, 
        nodes,
        edges,

        loading,
        setLoading,

        perms,
        
        init, 
        initPerms,
        initEdges,

        nodeStatus,
        setNodeStatus,

        addedNodes, 
        deletedNodes, 
        
        addedEdges, 
        deletedEdges, 
        
        selected, 
        select,
        unselect, 
        
        addNode,
        pushNode,
        pushNodeData,
        updateNodeData,

        getPath,
        isValidPath,
        
        save,
        ...flowProps
    } =  useMoodboardStore(useShallow(mbSelector))

    const params = useParams()
    const user = useUserStore(useShallow(userSelector))
    
    const mbId = params.mbId
    const socket = useRef<WebSocket>()
    
    const initialzie = async () => {
        if (mbId) {
            setLoading({on: true, progressText: "initializing..."})
            init(await getMoodboard(mbId))
            setLoading({on: false, progressText: ""})
        }
    }

    useEffect(() => { document.title = `${title} - Sponj3d` || "Sponj3d"}, [title])
    useEffect(() => { if (id) initPerms() }, [id])

    useEffect(() => {
        initialzie()
        if (user.isAuthenticated) {

            const sock = new WebSocket(`${process.env.REACT_APP_WS_URL}/user/${user.id}`)
            sock.onmessage = async (event) => {
                const {type, nid, status, data} = JSON.parse(event.data)

                switch (type) {
                    case "nodeUpdate":
                        console.log(`[onmessage] >> got message for ${nid} [${status}] (data)`, data)
                        if (nid && status) {
                            setNodeStatus(nid, status)
                            if (data) pushNodeData(nid, data)
                        }
                        break
                    case "moodboardUpdate":
                        console.log(`[onmessage] >> got a moodboard update (data)`, data)
                        if (data) init(data)
                        break
                    case "nodeAdd":
                        console.log(`[onmessage] >> got a node add (data)`, data)
                        if (data) pushNode(data)
                        if (nid && status) setNodeStatus(nid, status)
                        break
                    default:
                        console.warn(`[onmessage] >> got message of type ${type}`)
                        break
                }
            }

            socket.current = sock
            setInterval(() => {
                if (socket.current?.readyState === 1) socket.current.send(JSON.stringify({"signal": "heartbeat"}))
            }, 10000)
        }
    }, [user.isAuthenticated])

    useEffect(() => { user.init(); }, [])

    return (
        <ReactFlowProvider>
            <div {...props} className="height-100 width-100">
                {loading.on && <LoadingBar progressText={loading.progressText} style={{top: "var(--nav-top)"}}/>}
                <DefaultNav 
                    user={user} 
                    style={{zIndex: 1, right: 'var(--nav-left)', left: "auto"}}
                >
                    <button 
                        className="mb-save-btn"
                        onClick={() => {copyToClipboard(window.location.href)}}
                    >
                        Share
                    </button>
                </DefaultNav>
                <ReactFlow
                    fitView
                    nodes={nodes}
                    edges={edges}
                    {...flowProps}
                    minZoom={0.001}
                    panOnScroll
                    selectionOnDrag
                    panOnDrag={[1, 2]}
                    nodeTypes={nodeTypes}
                    proOptions={proOptions}
                    defaultEdgeOptions={edgeOptions}
                    onClick={(event) => {
                        if ((event.target as any).getAttribute("class")?.startsWith("react-flow__pane")) {
                            unselect()
                        }
                    }}
                    className="validationflow"
                >
                    <MiniMap position="top-left"/>
                    <Controls position="bottom-right"/>
                    <Background variant={BackgroundVariant.Dots} />
                </ReactFlow>
            
                <MoodboardToolbar />
            </div>
        </ReactFlowProvider>
    )
}
