// custom imports 
import { edgeType, mbType, nodeType } from "../types";
import { addToLocalStorage, any, filter, find } from "../../utils";
import { setStateType } from "../../types";
import { MoodBoardState, SaveState } from ".";
import { editMoodboard, editMoodboardUnsecure } from "../api";

export default function save(set: setStateType<MoodBoardState>, get: () => MoodBoardState): SaveState  {
    return {
        save: async () => {
            const state = get()
            
            let cachedMb: mbType = {} as mbType
            const cached = localStorage.getItem(state.id)

            if (cached) {
                cachedMb = JSON.parse(cached)
            }
            const updatedNodes = state.nodes.filter((node) => (
                !find(state.addedNodes, node, ['id']) && 
                !find(cachedMb?.nodes, node, ['id', 'data.title', 'data.src', 'position.x', 'position.y', 'status', 'type'])
            )) as nodeType[]

            if (    
                !updatedNodes.length && 
                !state.addedNodes.length && 
                !state.addedEdges.length &&
                !state.deletedEdges.length &&
                !state.deletedNodes.length 
            ) return 

            const deletedNodes = state.nodes.filter((node) => state.deletedNodes.includes(node.id)) as nodeType[]

            const { nodes: aNodes, addedNodes: addedANodes, deletedNodes: deletedANodes } = filterAnonNodes(updatedNodes, state.addedNodes, deletedNodes, eq => eq)
            const { nodes: naNodes, addedNodes: addedNANodes, deletedNodes: deletedNANodes } = filterAnonNodes(updatedNodes, state.addedNodes, deletedNodes, eq => !eq)
            
            state.setLoading({on: true, progressText: "saving..."})
  
            // handle unsecure changes
            if (aNodes.length || addedANodes.length || deletedANodes.length) {
                await editMoodboardUnsecure(
                    state.id, 
                    
                    filter<nodeType>(aNodes, {type: "comment"}, ['type'], undefined, eq => eq), 
                    filter<nodeType>(addedANodes, {type: "comment"}, ['type'], undefined, eq => eq), 
                    filter<nodeType>(deletedANodes, {type: "comment"}, ['type'], undefined, eq => eq).map(node => node.id), 
                )
            } 

            // handle secure changes
            if (
                (
                    naNodes.length || 
                    addedNANodes.length || 
                    deletedNANodes.length || 
                    state.addedEdges.length || 
                    state.deletedEdges.length 
                )
                &&
                (state.perms.isOwner || state.perms.isEditor)
            ) {
                const mb = await editMoodboard(
                    state.id, 
                    state.title, 
    
                    naNodes, 
                    addedNANodes, 
                    deletedNANodes.map(node => node.id), 
                    
                    state.deletedEdges, 
                    state.addedEdges
                )

                if (mb) addToLocalStorage(state.id, JSON.stringify(mb))
            }
            
            const edges: edgeType[] = []
            for (const edge of state.edges) {
                if (state.deletedEdges.includes(edge.id)) continue
                const added_edge = find<edgeType>(state.addedEdges, edge, ['id'])
                edges.push({...edge, data: {...edge?.data, ...added_edge?.data}} as edgeType)
            }

            const nodes: nodeType[] = []
            for (const node of state.nodes) {
                if (state.deletedNodes.includes(node.id)) continue
                const added_node = find<nodeType>(state.addedNodes, node, ['id'])
                nodes.push({...node, data: {...node?.data, ...added_node?.data}} as nodeType)
            }
            
            set({
                nodes: nodes,
                edges: edges,
    
                addedNodes: [],
                deletedNodes: [],
                
                addedEdges: [],
                deletedEdges: [],  
                loading: {on: false, progressText: undefined} 
            })
        },
    } 
}

function filterAnonNodes(nodes: nodeType[], addedNodes: nodeType[], deletedNodes: nodeType[], check: (eq: boolean) => boolean): {nodes: nodeType[], addedNodes: nodeType[], deletedNodes: nodeType[]} {
    return {
        nodes: nodes.filter((node) => check(!node.owner)), // nodes that are/are'nt anons
        addedNodes: addedNodes.filter((node) => check(!node.owner)), // nodes that are/are'nt anons
        deletedNodes: deletedNodes.filter((node) => check(!node.owner)), // nodes that are/are'nt anons
    }
}