// custom imports
import NodeHandle from "./handle";
import NodeHeader from "./header";
import NodeActions from "./actions";
import { selector } from "../../state";
import { nodeType, nodeTypes } from "../../types";
import { userType } from "../../../user/types";
import { useMoodboardStore } from "../../state/store";

// third party
import { Position } from "@xyflow/react";
import { useShallow } from "zustand/shallow";
import { find } from "../../../utils";
import { useMemo } from "react";

type BaseNodeProps = JSX.IntrinsicElements["div"] & {
    id: string
    title: string
    type: nodeTypes

    sources?: string[]
    targets?: string[]

    onRun?: () => void
}
export default function BaseNode({id, type, title, targets = [], sources = [], children, className, style, onClick, ...props}: BaseNodeProps) {
    const {
        nodes, 

        selected, 
        nodeStatus, 
        
        select, 
        unselect,
        
        setNodeStatus, 
    } = useMoodboardStore(useShallow(selector));

    const active = selected.includes(id)
    
    const node = useMemo(() => find<nodeType>(nodes, {id}, ['id']), [id])
    
    const owner = useMemo(() => node?.owner, [node])
    const status = useMemo(() => node?.status, [node])
    
    const isPending = useMemo(() =>status === "pending", [status])

    return (
        <div 
            className="node"
            onClick={event => { 
                event.stopPropagation()
                
                if (event.shiftKey) {
                    if (selected.includes(id)) {
                        unselect(id)
                    } else {
                        select(id)
                    }
                } else {
                    unselect()
                    select(id)
                }
                console.log(`[BaseNode] (selected) >>`, selected)
                onClick && onClick(event)
            }}
        >
            {!["comment"].includes(type) && 
                <NodeHeader 
                    id={id} 
                    value={title} 
                    style={{backgroundColor: `var(--node-title-color-${type})`}}
                />
            }

            <div 
                style={{...style, backgroundColor: `var(--node-color-${type}${owner?.id ? "" : "-anon"}, var(--node-color-default))`}}
                className={`
                    node-body 
                    node-${type}
                    ${className}
                    justify-center 
                    flex align-center 
                    ${isPending? "loading-border" : ""} 
                    node-${type}-${active? "active" : ""}
                `} 
            >
                <NodeHandle 
                    nid={id} 
                    type="target"
                    nodeType={type}
                    handles={targets}
                    position={Position.Left}
                />

                {!isPending && children}
                {isPending && 
                    <div 
                        className="spinner" 
                        // onClick={() => setNodeStatus(id, "ready")}
                    ></div>
                }

                <NodeHandle 
                    nid={id} 
                    type="source"
                    nodeType={type}
                    handles={sources}
                    position={Position.Right}
                />
            </div>

            {!["comment"].includes(type) && status !== "pending" && <NodeActions className={`${active ? "active" : ""}`} id={id} type={type} status={status}/>}
        </div>
    )
}
