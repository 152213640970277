// custom imports
import { remove } from "../utils";
import { setStateType } from "../../types";
import { edgeType, nodeType } from "../types";
import { FlowState, MoodBoardState } from ".";
import { find, filter, update } from "../../utils";

// third party 
import { generateUUID } from "three/src/math/MathUtils";
import { addEdge, applyEdgeChanges, applyNodeChanges,Node, Edge } from "@xyflow/react";

export default function flow(set: setStateType<MoodBoardState>, get: () => MoodBoardState): FlowState {
    return {
        nodes: [],
        edges: [],
        
        onNodesDelete: (nodes) => {
            const state = get()

            const {added, deleted} = remove<nodeType>(nodes, state.addedNodes, state.deletedNodes, ['id'])
    
            set({
                addedNodes: added, 
                deletedNodes: deleted
            })
    
            state.save()
        },
        onNodesChange: (changes) => {
            const state = get()
            set({
                nodes: applyNodeChanges(changes, state.nodes),
                addedNodes: applyNodeChanges(changes, state.addedNodes) as nodeType[],
            });
        },
        onNodeDragStop: () => {
            get().save()
        },

        onConnect: (conn) => {
            const state = get()
    
            const node: nodeType | undefined = find<nodeType>(state.nodes, {id: conn.source}, ['id'])
            const edge = {...conn, style: {stroke: `var(--node-title-color-${node?.type})`}} as edgeType
            set({
                edges: addEdge(edge, state.edges),
                addedEdges: [...state.addedEdges, {...edge, id: generateUUID()} as edgeType]
            })
            state.save()
        },
        onEdgesDelete: (edges) => {
            const state = get()

            console.log(`[onEdgesDelete] >>`, edges)
            const {added, deleted} = remove<edgeType>(edges, state.addedEdges, state.deletedEdges, ['source', 'target', 'sourceHandle', 'targeHandle'])
            
            set({
                addedEdges: added, 
                deletedEdges: deleted
            })
            state.save()
        },
        onEdgesChange: (changes) => {
            const state = get()
            
            set({
                edges: applyEdgeChanges(changes, state.edges),
                addedEdges: applyEdgeChanges(changes, state.addedEdges) as edgeType[],
            });
        },
        onEdgeMouseEnter: (event, edge) => {
            const state = get()
            set({
                edges: update<edgeType>(state.edges, edge, ['source', 'target', 'sourceHandle', 'targeHandle'], {style: {...edge.style, opacity: 1}}),
                addedEdges: update<edgeType>(state.addedEdges, edge, ['source', 'target', 'sourceHandle', 'targeHandle'], {style: {...edge.style, opacity: 1}})
            })
        },

        onEdgeMouseLeave: (event, edge) => {
            const state = get()

            if (edge.selected) return

            set({
                edges: update<edgeType>(state.edges, edge, ['source', 'target', 'sourceHandle', 'targeHandle'], {style: {...edge.style, opacity: 0.4}}),
                addedEdges: update<edgeType>(state.addedEdges, edge, ['source', 'target', 'sourceHandle', 'targeHandle'], {style: {...edge.style, opacity: 0.4}})
            })
        },

        onEdgeClick: (event, edge) => {
            const state = get()


            const edges = update<edgeType>(state.edges, {}, [], {style: {opacity: 0.4}})
            const addedEdges = update<edgeType>(state.addedEdges, {}, [], {style: {opacity: 0.4}})

            set({
                edges: update<edgeType>(edges, edge, ['source', 'target', 'sourceHandle', 'targeHandle'], {style: {opacity: edge.selected ? 0.4 : 1}, selected: !edge.selected}),
                addedEdges: update<edgeType>(addedEdges, edge, ['source', 'target', 'sourceHandle', 'targeHandle'], {style: {opacity: edge.selected ? 0.4 : 1}, selected: !edge.selected})
            })
        },
        
        isValidConnection: (edge) => {
            if (!edge.sourceHandle) return false
    
            const state = get()
            if (find(state.edges, edge, ['source', 'target', 'sourceHandle', 'targeHandle'])) return false
    
            switch (edge.targetHandle) {
                case "prompt":
                    if (edge.sourceHandle !== "txt") return false
                    break
                case "style":
                    // return false
                    if (["mesh", "sketch"].includes(edge.sourceHandle)) return false 
                    break 
                case "mesh":
                    break 
                case "geometry":
                    const target: Node | undefined = find<Node>(state.nodes, edge, ['id'], ['target'])
                    if (!target) return false 
    
                    const geoConns: Edge[] = filter(
                        state.edges, 
                        {
                            target: target?.id, 
                            targetHandle: "geometry", 
                        }, 
                        ['target', 'targetHandle'],
                        undefined, 
                        (eq) => eq
                    )
                    if (geoConns.length == 1 && geoConns[0].source !== edge.source) return false 
                    if (edge.sourceHandle === "mesh") return false
                    break 
                default: break
            }
            return true 
        },
    } 
}