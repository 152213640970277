// custom imports
import { BaseState } from "../../state"
import { permsType, userType } from "../../user/types"
import { nodeStatusType, nodeType, edgeType, mbType, nodeDataType } from "../types"

// third party
import { MouseEvent } from "react"
import { Node, Edge, Connection, EdgeChange, NodeChange } from "@xyflow/react"

export interface FlowState {
    nodes: Node[]
    edges: Edge[]

    onNodeDragStop: () => void
    onNodesDelete: (nodes: Node[]) => void
    onNodesChange: (changes: NodeChange[]) => void

    onConnect: (conn: Connection) => void
    onEdgesDelete: (edges: Edge[]) => void
    onEdgesChange: (changes: EdgeChange[]) => void
    onEdgeClick: (event: MouseEvent, edge: Edge) => void
    isValidConnection: (edge: Edge | Connection) => boolean
    onEdgeMouseEnter: (event: MouseEvent, edge: Edge) => void
    onEdgeMouseLeave: (event: MouseEvent, edge: Edge) => void
}

export interface SaveState {
    save: () => Promise<void | mbType>
}

export interface PathState {
    getPath: (start: string) => [Node, Edge[]][]
    isValidPath: (path: [Node, Edge[]][]) => boolean
}

export interface MoodBoardState extends FlowState, SaveState, PathState, BaseState {
    id: string
    title: string

    addedNodes: nodeType[]
    deletedNodes: string[]

    addedEdges: edgeType[]
    deletedEdges: string[]

    nodeStatus: Map<string, nodeStatusType>

    perms: permsType

    initPerms: () => void
    initEdges: () => void
    init: (mb: mbType) => void
    
    pushNode: (node: nodeType) => void 
    addNode: (node: Node, status: nodeStatusType, owner?: Partial<userType>) => void

    pushNodeData: (id: string, data: Partial<nodeDataType>) => void
    updateNodeData: (id: string, data: Partial<nodeDataType>) => void

    setNodeStatus: (id: string, status: nodeStatusType) => void
}

export const selector = (state: MoodBoardState) => ({
    id: state.id,
    title: state.title,
    nodes: state.nodes,
    edges: state.edges,

    loading: state.loading,
    setLoading: state.setLoading,
    
    perms: state.perms,
    
    init: state.init,
    initPerms: state.initPerms,
    initEdges: state.initEdges,

    addedNodes: state.addedNodes,
    deletedNodes: state.deletedNodes,

    addedEdges: state.addedEdges,
    deletedEdges: state.deletedEdges,

    nodeStatus: state.nodeStatus,
    
    selected: state.selected, 
    select: state.select,
    unselect: state.unselect,

    addNode: state.addNode,
    pushNode: state.pushNode,
    onNodesDelete: state.onNodesDelete,
    onNodesChange: state.onNodesChange,
    onNodeDragStop: state.onNodeDragStop,

    pushNodeData: state.pushNodeData,
    updateNodeData: state.updateNodeData,

    setNodeStatus: state.setNodeStatus,

    onConnect: state.onConnect,
    onEdgeClick: state.onEdgeClick,
    onEdgesChange: state.onEdgesChange,
    onEdgesDelete: state.onEdgesDelete,
    onEdgeMouseEnter: state.onEdgeMouseEnter,
    onEdgeMouseLeave: state.onEdgeMouseLeave,
    isValidConnection: state.isValidConnection,

    getPath: state.getPath,
    isValidPath: state.isValidPath,

    save: state.save
})