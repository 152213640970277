// custom imports
import { useNodeActions } from "../../hooks";
import { nodeStatusType, nodeTypes } from "../../types"
import { useUserStore } from "../../../user/state/store";
import { selector as userSelector } from "../../../user/state";

// third party
import { useParams } from "react-router-dom";
import { useShallow } from "zustand/shallow";
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// static data 
import actions from "../../../assets/data/actions.json"

type NodeActionsProps = JSX.IntrinsicElements["div"] & {
    id: string
    type: nodeTypes
    status?: nodeStatusType
}

export default function NodeActions({id, type, status, className = "", ...props}: NodeActionsProps) {
    const { run, download, caption } = useNodeActions()
    
    const { id: uid, isAuthenticated } = useUserStore(useShallow(userSelector))
    
    return (
        <div className={`node-actions flex align-center justify-between ${className}`}>
            <div>
                {Object.entries(actions).map(([action, {icon, tooltip, disabled: off, onStatus, onNodeTypes, authOnly}], i) => {
                    const margin = "0 5px 0"
                    const disabled = off || (!isAuthenticated && authOnly) 

                    let onClick = undefined
                    switch (action) {
                        case "run":
                            onClick = () => run(id, uid)
                            break 
                        case "download":
                            onClick = () => download(id)
                            break
                        case "caption":
                            onClick = () => caption(id)
                            break
                        default:
                            break
                    }

                    return status && onStatus.includes(status) && onNodeTypes.includes(type) ?
                        <NodeActionButton 
                            tooltip={tooltip}
                            onClick={onClick}
                            disabled={disabled}
                            icon={icon as IconProp}
                            key={`node-${action}-action-btn`}
                            style={{padding: 0, margin: margin}}
                            className={`icon-button ${disabled ? "disabled" : ""}`} 
                        />
                        : <></>
                })}
            </div>

            <NodeActionButton 
                disabled 
                tooltip="coming soon!" 
                className={`icon-button disabled"`} 
                style={{padding: 0, margin: "0 5px 0 0"}}
                icon={"fa-solid fa-ellipsis-vertical" as IconProp} 
            />
        </div>
    )
}

type NodeActionButtonProps = JSX.IntrinsicElements["button"] & {
    icon: IconProp
    tooltip?: string
}


function NodeActionButton({icon, tooltip, onClick, className = "", ...props}: NodeActionButtonProps) {
    return (
        <button
            {...props}

            className={`icon-button ${className}`} 
            onClick={event => {
                event.stopPropagation()
                onClick && onClick(event)
            }}
        >
            <FontAwesomeIcon icon={icon} />
            <span className="tooltip-bottom">{tooltip}</span>
        </button>
    )
}