// custom imports
import flow from "./flow"
import save from "./save"
import path from "./path"
import { MoodBoardState } from "."
import { onStatusUpdate } from "../utils"
import { find, update } from "../../utils"
import { getMbPerms } from "../../user/api"
import { permsType } from "../../user/types"
import { baseState } from "../../state/store"
import { edgeType, nodeStatusType, nodeType } from "../types"
import { deleteImg, getNodeData, getNodeStatus, uploadImg } from "../api"

// third party
import { create } from "zustand"
import { Node } from "@xyflow/react"
import { generateUUID } from "three/src/math/MathUtils"

export const useMoodboardStore = create<MoodBoardState>((set, get) => {
    const base = baseState<MoodBoardState>(set, get)
    return {
        ...base, 

        // override
        select(id) {
            base.select(id)

            const state = get()
            set({
                nodes: update<nodeType>(state.nodes, {id}, ['id'], {selected: true}),
                addedNodes: update<nodeType>(state.addedNodes, {id}, ['id'], {selected: true}),
            })
        },

        unselect(id) {
            base.unselect(id)

            const state = get()
            
            const keys = id ? ['id'] : []
            const edges = id ? state.edges : update<edgeType>(state.edges, {}, [], {style: {opacity: 0.4}})
            const addedEdges = id ? state.addedEdges : update<edgeType>(state.addedEdges, {}, [], {style: {opacity: 0.4}})
            
            set({
                nodes: update<nodeType>(state.nodes, {id}, keys, {selected: false}),
                addedNodes: update<nodeType>(state.addedNodes, {id}, keys, {selected: false}),

                edges: edges,
                addedEdges: addedEdges
            })
        },

        ...flow(set, get),
        ...save(set, get),
        ...path(set, get),

        id: "",
        title: "",

        addedNodes: [],
        deletedNodes: [],

        addedEdges: [],
        deletedEdges: [],

        nodeStatus: new Map(),

        perms: {} as permsType,

        init: async (mb) => {
            const state = get()
            const nodeStatus = new Map<string, nodeStatusType>()
            
            for (let i = 0; i < mb.nodes.length; i++) {
                const node = mb.nodes[i]
                switch (node.type) {
                    default:
                        nodeStatus.set(node.id, node.status)
                        break
                }   
            }

            set({
                ...mb,
                addedNodes: [],
                deletedNodes: [],

                addedEdges: [],
                deletedEdges: [],

                nodeStatus: nodeStatus,

                perms: await getMbPerms(mb.id)
            })
            
            get().initNodeData(mb)
        },

        async initNodeData(mb) {
            const state = get()
            const nodeStatus = new Map<string, nodeStatusType>()
            
            let edges = state.edges
            for (let i = 0; i < mb.nodes.length; i++) {
                const node = mb.nodes[i]

                let targetEdge: Partial<edgeType> = {}
                let sourceEdge: Partial<edgeType> = {style: {stroke: `var(--node-title-color-${node.type})`, opacity: 0.4}} 

                if (state.nodeStatus.get(node.id) === "pending") {
                    const status = (await getNodeStatus(node.id)).status
                    nodeStatus.set(node.id, status)
                    
                    switch (status) {
                        case "pending":
                            targetEdge.animated = true
                            targetEdge.deletable = true
                            break 
                        default: break
                    }

                    if (node.type) {
                        mb.nodes[i].data = await getNodeData(node.data.id)    
                    }
                }

                edges = update<edgeType>(edges, {target: node.id}, ['target'], targetEdge)
                edges = update<edgeType>(edges, {source: node.id}, ['source'], sourceEdge)

            }
            set({...mb, nodeStatus, edges})
        },
        
        addNode: async (node, status, owner) => {
            const state = get()
        
            set({
                nodes: [...state.nodes, {...node, status, owner} as Node],
                nodeStatus: onStatusUpdate(state.nodeStatus, node.id, "pending"),
                addedNodes: [...state.addedNodes, {...node, status, owner} as nodeType],
            })
            
            await state.save()

            set({
                nodeStatus: onStatusUpdate(state.nodeStatus, node.id, status),
            })

        },
        
        updateNodeData: async (id, data) => {
            const state = get()
            console.log(`[updateNodeData] >> updating node data for ${id}...`)

            const node: nodeType | undefined = find<nodeType>(state.nodes, {id}, ['id'])
            if (!node) return
            
            switch (node.type) {
                case "img":
                case "sketch":
                    if (state.perms.isOwner || state.perms.isEditor) {
                        if (data.img instanceof File) {
                            state.setLoading({on: true, progressText: "uploading image..."})
                            await uploadImg(state.id, node.id, data.img)
                            state.setLoading({on: false, progressText: ""})
                        } else if (!data.img && node.data?.img) {
                            state.setLoading({on: true, progressText: "deleting image..."})
                            await deleteImg(state.id, node.id)
                            state.setLoading({on: false, progressText: ""})
                        } else {
                            state.save()
                        }
                    }
                    break
                case "txt":
                case "mesh":
                case "comment":
                    state.save()
                    break
                default: break
            }

            set({
                nodes: update<Node>(state.nodes, {id}, ['id'], {data}),
                addedNodes: update<nodeType>(state.addedNodes, {id}, ['id'], {data}),
            })
        },
        
        setNodeStatus: (id, status) => {
            const state = get()
            const isPending = status === 'pending'

            set({
                nodeStatus: onStatusUpdate(state.nodeStatus, id, status),
                nodes: update<nodeType>(state.nodes, {id}, ['id'], {deletable: !isPending}),

                edges: update<edgeType>(state.edges, {target: id}, ['target'], {animated: isPending, deletable: !isPending})
            })
        },
    }
})