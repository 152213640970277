// custom imports
import { useNodeActions } from "../../hooks";
import { nodeStatusType, nodeTypes } from "../../types"
import { useUserStore } from "../../../user/state/store";
import { selector as userSelector } from "../../../user/state";

// third party
import { useParams } from "react-router-dom";
import { useShallow } from "zustand/shallow";
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type NodeActionsProps = JSX.IntrinsicElements["div"] & {
    id: string
    type: nodeTypes
    status?: nodeStatusType
}

export default function NodeActions({id, type, status, className = "", ...props}: NodeActionsProps) {
    const { run, download } = useNodeActions()
    
    const { id: uid, isAuthenticated } = useUserStore(useShallow(userSelector))

    return (
        <div className={`node-actions flex align-center justify-between ${className}`}>
            <div>
                {["mesh", "generatedImg"].includes(type) && status !== "pending" &&
                    <>
                    <button
                        disabled={!isAuthenticated}
                        style={{padding: 0, margin: "0 5px 0 0"}}
                        className={`icon-button ${!isAuthenticated ? "disabled" : ""}`} 
                        onClick={(event) => {
                            event.stopPropagation()
                            if (uid) {
                                run(id, uid) 
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={"fa-solid fa-circle-play" as IconProp} />
                        <span className="tooltip-bottom">run</span>
                    </button>
                    <button
                        style={{padding: 0, margin: "0 5px"}}
                        className={`icon-button`} 
                        onClick={(event) => {
                            event.stopPropagation()
                            download(id)
                        }}
                    >
                        <FontAwesomeIcon icon={"fa-solid fa-download" as IconProp} />
                        <span className="tooltip-bottom">download</span>
                    </button>
                    </>
                }
            </div>
            
            <button 
                disabled
                style={{padding: 0, margin: 0}}
                className={`icon-button disabled"`} 
            >
                <FontAwesomeIcon icon={"fa-solid fa-ellipsis-vertical" as IconProp} />
                {/* <span className="tooltip">coming soon!</span> */}
            </button>
        </div>
    )
}