// custom imports
import { useCustomState } from "../utils";
import { Modal, ModalProps } from "../components/modal";
import { useUserStore } from "./state/store";
import { selector as userSelector } from "./state";

// third party
import { useShallow } from "zustand/shallow";

type SinginModalProps = ModalProps & {
}

export function SigninModal({open, className = "", ...props}: SinginModalProps) {
    const [{email, password}, set] = useCustomState<{email: string, password: string}>({email: "", password: ""})

    const { signin } = useUserStore(useShallow(userSelector))

    return (
        <Modal open={typeof open === "boolean" ? open : true} {...props} className={`flex column align-center login-modal ${className}`}>
            <h1 style={{margin: 0}}>Sponj</h1>
            <h3 className="bold">Sign in to start creating!</h3>
            
            <span className="flex column" style={{width: "80%"}}>
                <input 
                    type="email"
                    value={email}
                    placeholder="Email" 
                    className="form-field" 
                    onChange={event => set({email: event.target.value})}
                />
                <input 
                    type="password"
                    value={password}
                    placeholder="Password" 
                    className="form-field __password" 
                    onChange={event => set({password: event.target.value})}
                />
            </span>

            <button onClick={event => signin(email, password)}>Login</button>
        </Modal>
    )
}